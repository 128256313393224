import React from 'react'
import './footer.css';
const Footer = () => {
  return (
    <footer className='footer'>
        Fatmanurgulen tarafından yapılmıştır tüm hakları saklıdır.
    </footer>
  );
}
export default Footer;
